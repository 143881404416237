import * as R from 'ramda'
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { useNavigate } from '@reach/router'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faFilePdf } from '@fortawesome/free-regular-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import FsLightbox from 'fslightbox-react'

import { mapIndexed, notNilOrEmpty } from '../lib/Helpers'
import BlockContent from '../components/BlockContent'
import ContactFormModal from '../components/ContactFormModal'
import ExhibitorsGrid from '../components/ExhibitorsGrid'
import Hero from '../components/Hero'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

export default props => {
  let fileAssets = [],
    videosURLs = []
  const { data } = props

  const { sanityExhibitors: exhibitor } = data
  const { sanitySiteSettings: settings } = data

  const { edges: companies } = data.allSanityExhibitors
  const { edges: categories } = data.allSanityCategory

  const [token, setToken] = React.useState('')
  const [noCaptchaToken, setNoCaptchaToken] = React.useState('')

  const [defaultOrder, setDefaultOrder] = React.useState([])
  const [selectedCategory, setSelectedCategory] = React.useState('')
  const [selectedSubCategory, setSelectedSubCategory] = React.useState('')
  const [defaultSort, setDefaultSort] = React.useState('asc')
  const [filesLightbox, toggleFilesLightbox] = React.useState(false)
  const [isWhoAreYouImgOpen, setIsWhoAreYouImgOpen] = React.useState(false)
  const [isWhatYouBuildImgOpen, setIsWhatYouBuildImgOpen] = React.useState(
    false
  )
  const [videosLightbox, toggleVideosLightbox] = React.useState(false)

  const navigate = useNavigate()

  const FormModal = withReactContent(Swal)

  function verifyCallback(recaptchaToken) {
    // Here you will get the final recaptchaToken!!!
    setToken(recaptchaToken)
  }

  React.useEffect(() => {
    loadReCaptcha(process.env.GATSBY_GOOGLE_RECAPTCHA)
    initialCompaniesOrder(companies)
    handleCategoryChange(selectedCategory)
  }, [])

  function onAutocompleteChange(event, value) {
    navigate(`/exhibitor/${value.node.slug.current}`)
  }

  function initialCompaniesOrder(comps) {
    R.compose(
      e => setDefaultOrder(e),
      R.sort(R.ascend(R.path(['node', 'title'])))
    )(comps)
  }

  function handleCategorySort(sort) {
    if (R.equals(sort, 'asc')) {
      R.compose(
        () => setDefaultSort(sort),
        e => setDefaultOrder(e),
        R.sort(R.ascend(R.path(['node', 'title'])))
      )(defaultOrder)
    } else {
      R.compose(
        () => setDefaultSort(sort),
        e => setDefaultOrder(e),
        R.sort(R.descend(R.path(['node', 'title'])))
      )(defaultOrder)
    }
  }

  function handleVideoClick() {
    const video = exhibitor.company_videos[0]
    R.cond([
      [R.equals('youtube'), () => toggleVideosLightbox(!videosLightbox)],
      [
        R.T,
        () => {
          if (typeof window !== 'undefined') {
            window.open(video.video_url)
          }
        }
      ]
    ])(video.video_source)
  }

  function handleCategoryChange(catName) {
    const newCatsArray = []
    setDefaultSort(defaultSort)
    setSelectedCategory(catName)

    notNilOrEmpty(catName)
      ? R.compose(
          () => initialCompaniesOrder(newCatsArray),
          mapIndexed(({ node: item }, index) => {
            let eq = R.equals(catName, item.categories.title)
            if (eq) {
              newCatsArray.push(companies[index])
            }
          })
        )(companies)
      : initialCompaniesOrder(companies)
  }

  function handleSubCategoryChange(subName) {
    const newSubArray = []
    setDefaultSort(defaultSort)
    setSelectedSubCategory(subName)

    notNilOrEmpty(subName)
      ? R.compose(
          () => initialCompaniesOrder(newSubArray),
          mapIndexed(({ node: item }, index) => {
            let s = R.find(R.propEq('sub_name', subName))(
              item.categories.subcategories
            )

            console.log(R.equals(s.sub_name, subName))
            console.log(companies)
            if (notNilOrEmpty(s)) {
              newSubArray.push(companies[index])
            }
          })
        )(companies)
      : initialCompaniesOrder(companies)
  }

  return (
    <Layout>
      <ReCaptcha
        sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA}
        action="homepage"
        verifyCallback={verifyCallback}
      />
      <SEO
        title={`${exhibitor.title} | Afwerx Challenge Virtual Tradeshow`}
        keywords={settings.keywords}
        description={exhibitor.title}
        author={settings.author}
        image={exhibitor.company_img.asset.fluid.src}
        url={`${settings.site_url}/exhibitor/${exhibitor.slug.current}`}
      />
      <Hero
        title={exhibitor.title}
        className="exhibitor-page"
        img={require('../assets/imgs/hero-small.png')}
      />
      <div className="container exhibitor-page">
        <div className={`section-content`}>
          <div className="section-content__content">
            <div className="left">
              <p className="keys">
                <strong>Contact Name: </strong>
                {exhibitor.company_primary_contact}
              </p>
              {exhibitor.company_phone && (
                <p className="keys">
                  <strong>Company Phone: </strong>
                  <a
                    href={`tel:${exhibitor.company_phone}`}
                    className="link--black"
                  >
                    {exhibitor.company_phone}
                  </a>
                </p>
              )}
              {exhibitor.company_url && (
                <p className="keys">
                  <strong>Company Website: </strong>
                  <a
                    href={exhibitor.company_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link link--black"
                  >
                    {exhibitor.company_url}
                  </a>
                </p>
              )}
            </div>
            <div className="right">
              <Img
                fluid={exhibitor.company_img.asset.fluid}
                objectFit="scale-down"
                objectPosition="50% 50%"
              />
            </div>
          </div>
          <hr />
          <div className="section-content__content">
            <div className="left">
              <h2>Who are we: </h2>
              {exhibitor.bc__who_are_you}
              <div style={{ marginTop: 20 }}>
                <h4 className="title">
                  <a
                    href={exhibitor.submission_details_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link link--black"
                  >
                    More info >>
                  </a>
                </h4>
              </div>
            </div>
            {notNilOrEmpty(exhibitor.bc__company_who_are_you_img) &&
              notNilOrEmpty(exhibitor.bc__company_who_are_you_img.asset) && (
                <div className="right text--center">
                  <FsLightbox
                    toggler={isWhoAreYouImgOpen}
                    sources={[
                      exhibitor.bc__company_who_are_you_img.asset.fluid.src
                    ]}
                  />
                  <div
                    className="image-link-wrapper text--center"
                    onClick={() => setIsWhoAreYouImgOpen(!isWhoAreYouImgOpen)}
                  >
                    <Img
                      fluid={exhibitor.bc__company_who_are_you_img.asset.fluid}
                      objectFit="scale-down"
                      objectPosition="50% 50%"
                    />
                  </div>
                  <small>Click to enlarge</small>
                </div>
              )}
          </div>
          <hr />
          <div className="links--2-col">
            {settings.zoom_links && notNilOrEmpty(exhibitor.company_zoom_url) && (
              <div className="rect rect--black">
                <a
                  href={exhibitor.company_zoom_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require('../assets/imgs/showcase_hours.svg')}
                    alt="zoom logo"
                  />
                </a>
                <h4 className="title">OFFICE HOURS</h4>
                <a
                  href={exhibitor.company_zoom_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link--white"
                >
                  <p>Join the live meeting</p>
                </a>
              </div>
            )}
          </div>
          <div className="links">
            {notNilOrEmpty(exhibitor.company_videos) && (
              <div>
                <h4>Company Videos</h4>
                <div className="outline links__video links__icon">
                  <a onClick={() => handleVideoClick()} className="link--black">
                    <FontAwesomeIcon
                      icon={faYoutube}
                      mask={['circle']}
                      size="8x"
                    />
                  </a>
                </div>
                {mapIndexed((video, i) => {
                  videosURLs.push(
                    <iframe
                      allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      className="lightbox__iframe__videos"
                      frameBorder="0"
                      height="1080"
                      key={i}
                      src={R.cond([
                        [
                          R.equals('youtube'),
                          R.always(
                            `https://www.youtube.com/embed/${video.video_url}`
                          )
                        ],
                        [
                          R.equals('gdrive'),
                          R.always(
                            `https://drive.google.com/file/d/${video.video_url}/preview`
                          )
                        ],
                        [R.T, R.always(video.video_url)]
                      ])(video.video_source)}
                      title="YouTube Video"
                      type={R.cond([
                        [R.equals('youtube'), R.always(`youtube`)],
                        [R.T, R.always('video')]
                      ])(video.video_source)}
                      width="1920"
                    />
                  )
                })(exhibitor.company_videos)}
              </div>
            )}
            {notNilOrEmpty(exhibitor._rawCompanyFileAsset) && (
              <div>
                <h4>Brochures ({exhibitor._rawCompanyFileAsset.length})</h4>
                <div className="outline links__video links__icon">
                  <a
                    onClick={() => toggleFilesLightbox(!filesLightbox)}
                    className="link--black"
                  >
                    <FontAwesomeIcon
                      icon={faFilePdf}
                      mask={['circle']}
                      size="8x"
                    />
                  </a>
                  {mapIndexed((file, i) => {
                    fileAssets.push(
                      <iframe
                        allow="fullscreen"
                        allowFullScreen
                        className="lightbox lightbox__iframe__files"
                        frameBorder="0"
                        height="1080"
                        key={i}
                        src={file.asset.url}
                        title={file.asset.originalFilename}
                        width="1920"
                      />
                    )
                  })(exhibitor._rawCompanyFileAsset)}
                </div>
              </div>
            )}
            <div>
              <h4>Leave Company a Message</h4>
              <div className="links__contact outline links__icon">
                <a
                  onClick={() =>
                    FormModal.fire({
                      title: 'Contact Us',
                      // text: 'Do you want to continue',
                      // icon: 'success',
                      showConfirmButton: false,
                      confirmButtonText: 'Send',
                      showCloseButton: false,
                      // width: 1600,
                      heightAuto: false,
                      padding: '3em',
                      customClass: {
                        popup: 'popup',
                        confirmButton: 'btn btn--black btn-confirm'
                      },
                      html: (
                        <ContactFormModal
                          exhibitorEmail={
                            exhibitor.company_primary_contact_email
                          }
                          exhibitorContact={exhibitor.company_primary_contact}
                          token={token}
                          setNoCaptchaToken={setNoCaptchaToken}
                        />
                      ),
                      preConfirm: () => {
                        var pageTitle = document.title

                        // putting the document title back to original
                      }
                    })
                  }
                  className="link--black"
                >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    mask={['circle']}
                    size="8x"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*
       * This is the lightbox for the PDF files
       */}
      <FsLightbox toggler={filesLightbox} customSources={fileAssets} />
      {/*
       * This is the lightbox for the videos
       */}
      <FsLightbox
        toggler={videosLightbox}
        customSources={videosURLs}
        loadOnlyCurrentSource
        maxYoutubeVideoDimensions={{ width: 1920, height: 1080 }}
      />
      <div className="container">
        <h3>KEEP EXPLORING</h3>
      </div>
      <ExhibitorsGrid
        categories={categories}
        defaultOrder={defaultOrder}
        defaultSort={defaultSort}
        handleCategoryChange={handleCategoryChange}
        handleSubCategoryChange={handleSubCategoryChange}
        handleCategorySort={handleCategorySort}
        onAutocompleteChange={onAutocompleteChange}
        selectedCategory={selectedCategory}
        selectedSubCategory={selectedSubCategory}
      />
    </Layout>
  )
}

export const exhibitorQuery = graphql`
  query ExhibitorTemplateQuery($_id: String!) {
    sanitySiteSettings {
      author
      keywords
      site_url
      title
      zoom_links
    }
    allSanityCategory {
      edges {
        node {
          title
          slug {
            current
          }
          _rawSubcategories(resolveReferences: { maxDepth: 10 })
        }
      }
    }
    sanityExhibitors(_id: { eq: $_id }) {
      _rawCompanyFileAsset(resolveReferences: { maxDepth: 10 })
      categories {
        title
      }
      company_url
      company_phone
      company_videos {
        video_url
        video_source
      }
      company_primary_contact
      company_primary_contact_email
      company_img {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      company_zoom_url
      submission_details_url
      bc__company_who_are_you_img {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      bc__who_are_you
      slug {
        current
      }
      title
    }
    allSanityExhibitors {
      edges {
        node {
          title
          company_img {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
              fixed(width: 300) {
                ...GatsbySanityImageFixed
              }
            }
          }
          categories {
            title
            subcategories {
              sub_name
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
